<template>
  <div>
    <div class="container-fluid p-0">
      <img src="../../assets/images/agregados.png" />
    </div>
    <div class="container px-5 pt-5">
      <div class="d-flex justify-content-center flex-column">
        <div class="row justify-content-center">
          <div class="col-8">
            <p>
              <b>Noah Rosa</b> é Brand Designer e UX Designer. Acredita que o
              design está altamente conectado com progressos sociais, e que,
              quanto mais contribui, mais as mudanças têm chances de acontecer.
            </p>
            <p>
              <b>Kérley Winques</b> é orientadora do trabalho. Mestre e doutora
              em jornalismo. Ama pesquisa e acredita que o jornalismo é uma
              potente ferramenta de transformação social.
            </p>
            <p>
              <b>Lucas Leoni</b> é estudante de Sistemas para Internet, movido
              por tecnologia e inovação. Para ele a tecnologia atualmente tem
              grande impacto na sociedade, por isso dedica-se sempre para que
              esse impacto seja positivo.
            </p>
            <p>
              "Foi muito gratificante contribuir para este projeto com o
              desenvolvimento deste site."
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Agregados",
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
