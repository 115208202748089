<template>
  <div></div>
</template>

<script>
export default {
  name: "BgNavbar",
}
</script>

<style scoped>
div {
  height: 3rem;
}
</style>