<template>
  <div class="container-fluid p-0">
    <img src="../../assets/images/deni.jpg">
  </div>
</template>

<script>
export default {
  name: "Imagem1",
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>