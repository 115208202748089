<template>
  <div>
    <div class="container px-5 pt-5 pb-4">
      <div class="d-flex justify-content-center flex-column">
        <div class="row justify-content-center">
          <div class="col-8">
            <h2>Sobre nós</h2>
            <p>
              Este webdocumentário foi produzido pelos estudantes de Jornalismo
              Pedro Novais e Kevin Eduardo para a disciplina de Projeto
              Experimental da Faculdade Ielusc, em parceria com Noah Rosa
              (Publicidade e Propaganda) e Lucas Leoni (Sistemas para Internet),
              com orientação da professora Kérley Winques.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <img src="../../assets/images/pedro.png" />
    </div>
    <div class="container px-5 pt-5 pb-4">
      <div class="d-flex justify-content-center flex-column">
        <div class="row justify-content-center">
          <div class="col-8">
            <p>
              <b>Pedro Novais</b> é estudante de Jornalismo, redator, locutor e
              metido a roteirista. Ama música e é apaixonado por filmes e
              séries.
            </p>
            <p>
              “Fazer parte deste projeto multidisciplinar — e tão humano — foi
              um constante aprendizado e me permitiu colocar em prática as
              várias facetas do jornalismo. Os encontros com os Deni foram
              momentos muito ricos de conversas, desabafos, trocas, risadas e
              muita história. Histórias de revolta e tristeza, mas também de
              acolhimento, de união, de amor e de luta. Histórias reais de
              pessoas reais.”
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pedro",
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
