<template>
  <span>
    <p>
      A pandemia da Covid-19 afetou milhares de famílias brasileiras. Com a
      família de Thaynã Santana Sampaio de Araújo e Dorval Pereira Tavares não
      foi diferente. O casal, que é indígena, da etnia Deni, decidiu deixar a
      terra natal, Manaus, em 2021, por conta do estrago que a doença causou no
      estado.
    </p>
    <p>
      Manaus foi uma das regiões brasileiras mais afetadas pela Covid-19 e
      <a
        href="https://g1.globo.com/am/amazonas/noticia/2021/01/14/covid-19-manaus-vive-colapso-com-hospitais-sem-oxigenio-doentes-levados-a-outros-estados-cemiterios-sem-vagas-e-toque-de-recolher.ghtml"
        target="_blank">viveu um colapso na saúde</a
      >
      naquele ano. Faltava oxigênio nos hospitais para tratar os pacientes.
    </p>
    <p>
      O casal decidiu vender tudo que tinha lá e vir para Joinville, Santa
      Catarina, a cidade que, segundo eles, tinha oportunidades e onde outros
      membros da família já estavam morando. No município catarinense, a família
      pretendia recomeçar a vida, conseguir melhores condições de trabalho e de
      estudo para os três filhos.
    </p>
    <p>
      Para dar início ao novo sonho, desembolsaram cerca de R$ 7 mil para
      comprar um terreno no bairro onde o irmão de Dorval, Valdemir Lopes
      Tavares, e a cunhada Celia Batista Tavares moram. A compra foi feita pela
      OLX, um site de vendas. Com o restante do dinheiro, adquiriram os
      materiais necessários para levantar uma casa.
    </p>
    <p>
      Porém, ao chegarem na cidade, depararam-se com preconceito e violência por
      parte das autoridades locais.
    </p>
    <p>
      O conflito se deu pela localização da moradia. O terreno era, na verdade,
      uma Área de Preservação Permanente (APP), em uma região de mangue poluída,
      no bairro Fátima, Zona Sul de Joinville.
    </p>
    <p>
      No dia 23 de março de 2021, a família de Dorval e Thaynã foi surpreendida
      por uma ação coordenada pela Secretaria do Meio Ambiente de Joinville
      (SAMA) e efetivada pela Polícia Militar e pela Guarda Municipal para a
      demolição da casa. A prefeitura alegou que estava cumprindo uma decisão da
      Justiça. A operação, marcada pela truculência policial e pelo preconceito,
      repercutiu na mídia local, e até fora do município. Apesar do apoio que
      tiveram, mensagens de cunho racista e xenofóbico contra as famílias também
      circularam nas redes sociais.
    </p>
  </span>
</template>

<script>
export default {
  name: "TextColumn1",
};
</script>

<style scoped>
a {
  color: #b41917;
  text-decoration: underline;
  font-weight: bold;
}
</style>
