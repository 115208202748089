<template>
  <span>
    <p>A casa não foi derrubada. Por enquanto, permanece em pé.</p>
    <p>
      Já faz mais de um ano que Thaynã e Dorval e os outros familiares vivem em
      Joinville. Não é só o casal que lida com o medo do despejo, todos os
      membros da família também foram incluídos nesse processo. São 13 adultos e
      22 crianças. O caso segue vagarosamente na Justiça, e eles aguardam com
      esperança.
    </p>
  </span>
</template>

<script>
export default {
  name: "TextColumn2",
};
</script>
