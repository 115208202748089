<template>
  <span>
    <p>
      A palavra “indígena” significa "originário, aquele que está ali antes dos
      outros" e valoriza a diversidade de cada povo. Por isso, é inadequado o
      uso da expressão <b>“não civilizado”</b> para se dirigir aos povos
      indígenas e suas culturas, tendo em vista que, comumente, o parâmetro
      utilizado para afirmar o que é ou não civilizado é um parâmetro racista e
      eurocêntrico.
    </p>
    <p>
      Os povos indígenas do Brasil compreendem um grande número de diferentes
      grupos étnicos que habitam o país desde milênios antes do início da
      colonização portuguesa, no século 15.
    </p>
    <p>
      Segundo dados de uma pesquisa publicada em 2016 pelo Instituto Brasileiro
      de Geografia e Estatística (IBGE), atualmente existem cerca de 305 etnias
      e ao menos 274 línguas indígenas no Brasil. A região Norte abriga a maior
      parcela de povos originários brasileiros (37,4%), seguida pelo Nordeste
      (25,5%), Centro-Oeste (16%), Sudeste (12%) e Sul (9,2%).
    </p>
  </span>
</template>

<script>
export default {
  name: "TextColumnBox",
};
</script>
