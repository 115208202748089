<template>
  <div class="container-fluid px-0 py-5">
    <img src="../../assets/images/laudo.jpg" />
    <div class="col d-flex justify-content-center">
      <em class="mt-3 px-4 text-white">
        Uma das crianças chegou a ser levada para uma Unidade Básica de Saúde do
        bairro, devido à intoxicação por spray de pimenta usado durante a ação.
      </em>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imagem",
};
</script>

<style scoped>
.container-fluid {
  background-color: #584212;
}
img {
  width: 100%;
}
</style>
