<template>
  <span>
    <p>
      <b>
        <i>
          “É obrigação da União fazer a proteção da população indígena”, frase
          de Cynthia
        </i>
      </b>
    </p>
    <p>
      Mas a realidade no Brasil tem sido outra, desde a eleição de Jair Messias
      Bolsonaro.
    </p>
    <p>
      Organizações internacionais e de proteção aos direitos humanos têm
      apontado para um retrocesso à proteção dos povos indígenas no Brasil.
      Recentemente, segundo o jornalista Jamil Chade, o governo
      <a
        href="https://noticias.uol.com.br/colunas/jamil-chade/2022/05/19/bolsonaro-ignorou-todas-as-recomendacao-da-onu-para-a-defesa-de-indigenas.htm"
        target="_blank">
        Bolsonaro ignorou todas as recomendações</a
      >
      da Organização das Nações Unidas (ONU) para a defesa dos indígenas. Mesmo
      na época aceitando, o governo não cumpriu nenhuma delas, conforme a
      avaliação de mais de 100 entidades de organizações indígenas,
      indigenistas, ambientalistas e de direitos humanos.
    </p>
    <p>
      O país também vê, cotidianamente, o aumento do desmatamento e invasão em
      terras indígenas por parte do garimpo ilegal, algo que o
      <a href="https://www.youtube.com/watch?v=JoS2WjsCSYE"
        target="blank">governo tenta normalizar</a
      >. Em seis anos houve um aumento gigantesco do desmatamento em terras
      indígenas devido ao garimpo ilegal, segundo estudo da MapBiomas. Em 2016,
      58,4 hectares foram destruídos e em 2021, 2.409 hectares — um crescimento
      de 41 vezes.
    </p>
    <p>
      Em junho deste ano, o Instituto de Estudos Socioeconômicos (Inesc) e a
      associação que representa servidores e indigenistas da Funai (INA)
      produziram
      <a
        href="https://www.inesc.org.br/wp-content/uploads/2022/06/Fundacao-anti-indigena_Inesc_INA.pdf"
        target="_blank">um dossiê</a
      >
      sobre os retrocessos e ilegalidades da Funai sob a gestão de Bolsonaro e
      Marcelo Xavier. O dossiê foi lançado no mesmo
      <a
        href="https://www.cnnbrasil.com.br/nacional/suspeito-confessa-assassinato-de-dom-phillips-e-bruno-pereira-dizem-fontes-da-pf/"
        target="blank">mês do assassinato</a
      >
      do jornalista britânico Dom Philips e do indigenista Bruno Pereira —
      referências como defensores dos direitos indígenas.
    </p>
    <p>
      Também em junho, os funcionários pediram a renúncia de Xavier (atual
      presidente da Funai). Eles acusam-no de promover uma gestão anti-indígena
      e anti-indigenista.
    </p>
    <p>
      O órgão também tem passado por um processo de militarização. Das 39
      coordenadorias regionais, 21 são chefiadas por militares ou policiais.
    </p>
    <em>Fontes: UOL, Jornal Nacional, O Globo, Metrópoles</em>
  </span>
</template>

<script>
export default {
  name: "TextColumnBox2",
};
</script>

<style scoped>
em {
  font-size: 1rem;
}
a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: bold;
}
</style>
