<template>
  <div class="container-fluid p-0">
    <img src="../../assets/images/biblia.jpg">
  </div>
</template>

<script>
export default {
  name: 'Imagem',
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>