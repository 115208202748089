<template>
  <span>
    <p>
      Você lembra que lá no primeiro episódio informamos que todas as famílias da comunidade foram incluídas
      no processo de despejo?
    </p>
    <p>
      Pois é, segundo a advogada popular Cynthia Pinto da Luz, essa medida foi
      tomada por se tratar de uma ocupação coletiva. “Infelizmente a Justiça
      reconhece que todos que estão na área de ocupação independente de serem
      identificados (como indígenas), são classificados como invasores
      desconhecidos”, explica. “É uma forma de criminalizar o direito à
      moradia.”
    </p>
  </span>
</template>

<script>
export default {
  name: "TextColumn1",
};
</script>
