import { render, staticRenderFns } from "./BgNavbar.vue?vue&type=template&id=150f6781&scoped=true"
import script from "./BgNavbar.vue?vue&type=script&lang=js"
export * from "./BgNavbar.vue?vue&type=script&lang=js"
import style0 from "./BgNavbar.vue?vue&type=style&index=0&id=150f6781&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150f6781",
  null
  
)

export default component.exports