<template>
  <div class="container pt-4 d-flex justify-content-center">
    <em>
      Este infográfico representa apenas alguns núcleos familiares da
      comunidade.
    </em>
  </div>
</template>

<script>
export default {
  name: "Legenda",
};
</script>
