<template>
  <span>
    <p>
      Guerreiros da Noite. Assim são conhecidos os Deni. Segundo a Apib
      (Articulação dos Povos Indígenas do Brasil), os Deni fazem parte das
      sociedades indígenas que vivem na região entre os rios Juruá e Purus, nos
      municípios de Itamarati, Lábrea, Pauini e Tapauá, no estado do Amazonas.
    </p>
    <p>
      Como Valdecir relata neste episódio, na década de 1940, o
      <b>segundo ciclo da borracha</b> atraiu milhares de migrantes para a
      região, que lhes trouxeram doenças e estabeleceram disputas territoriais
      violentas, que culminaram na exploração da mão-de-obra indígena.
    </p>
    <p>
      Os Deni passaram por décadas de abusos e violências até terem seus
      direitos territoriais assegurados. Foi preciso uma campanha com a ajuda de
      Organizações Não Governamentais (Ongs), como a Greenpeace, para então
      conseguirem a
      <a
        href="https://ciencia.estadao.com.br/noticias/geral,denis-celebram-a-demarcacao-de-suas-terras,20030804p73357"
        target="_blank">demarcação oficial</a
      >, que só foi conquistada em 5 de agosto de 2003.
    </p>
    <p>
      Atualmente, os Deni residem em uma área reconhecida como
      <b>Terra Indígena (TI)</b>. Entretanto, eles ainda enfrentam problemas
      relacionados às constantes invasões para atividades clandestinas, como
      pesca e extração de madeira. “Tenho parentes que são praticamente escravos
      lá”, relata Dorval. Ele conta que, ainda hoje, pescadores se aproveitam
      dos indígenas, os coagindo a acordos trabalhistas precários.
    </p>
    <p>
      A língua Deni pertence à família linguística Arawá. Os Deni do rio Xeruã
      se autodenominam "Jamamadi-Deni", enquanto os Deni do rio Cuniuá — região
      onde moram os familiares de Thaynã e Dorval — se autodenominam
      "Madihá-Deni".
    </p>
    <p>
      Esta distinção é resultado da influência exercida pelo grupo missionário
      evangélico Missão Novas Tribos do Brasil, organização estadunidense que
      atua na região do rio Cuniuá desde 1982. Dados do Sistema de Informação da
      Atenção à Saúde Indígena (Siasi) apontam que, em 2013, a TI Deni possuía
      uma população de cerca de <b>1470 pessoas</b>. O levantamento também
      indica a existência de oito aldeias, com a presença de 13 subgrupos da
      etnia.
    </p>
  </span>
</template>

<script>
export default {
  name: "TextColumn1",
};
</script>

<style scoped>
a {
  color: #b41917;
  text-decoration: underline;
  font-weight: bold;
}
</style>
