<template>
  <div class="bg py-5 px-5">
    <div
      class="container d-flex justify-content-center embed-responsive embed-responsive-16by9"
    >
      <iframe
        class="embed-responsive-item"
        src="https://www.youtube.com/embed/lZ94GJjrP4A"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoYt",
};
</script>

<style scoped>
.bg {
  background-color: #1e3b1d;
}
</style>
