<template>
  <span>
    <p>
      O termo <b>“invasão”</b> é usado corriqueiramente como forma de
      criminalizar as ocupações urbanas e seus moradores.
    </p>
    <p>
      Ao se referir a esses locais, use a palavra <b>ocupação</b>. “Ocupa quem
      precisa de um lugar para morar e não pode pagar por isso, quem invade na
      verdade são os grandes latifundiários, loteadores e grileiros de terra”,
      enfatiza Cynthia.
    </p>
  </span>
</template>

<script>
export default {
  name: "TextColumnBox",
};
</script>
