<template>
  <span>
    <p>
      As terras indígenas são porções do território brasileiro habitadas por
      povos indígenas, destinadas à reprodução física, econômica, social e
      cultural destes grupos, de acordo com seus costumes, tradições e usos.
      Esses territórios podem ser divididos em 4 tipos:
    </p>
    <p>
      <b>Terras Indígenas Tradicionalmente Ocupadas:</b> tratadas no parágrafo
      1º do artigo 231 da Constituição, são as terras de direito originário dos
      povos indígenas, cujo processo de demarcação é feito com base no Decreto
      n.º 1775/96. Este é o tipo de território que as tribos Deni vivem.
    </p>
    <p>
      <b>Reservas Indígenas:</b> são terras doadas por terceiros, adquiridas
      e/ou desapropriadas pela União e que se destinam à posse permanente dos
      povos indígenas. Também pertencem ao patrimônio da União e não
      correspondem às terras de ocupação tradicional da etnia em questão, tal
      como ocorre na forma anterior.
    </p>
    <p>
      <b>Terras Dominiais:</b> são adquiridas por compra ou por doação e são de
      propriedade das comunidades indígenas. Esse é o único caso em que as
      terras não são apenas de usufruto dos indígenas e propriedade da União.
    </p>
    <p>
      <b>Interditadas:</b> são áreas interditadas para proteção dos povos
      indígenas em isolamento voluntário, com controle de entrada e circulação
      de terceiros. A interdição da área pode ser realizada, ou não, juntamente
      com o processo de demarcação disciplinado pelo Decreto n.º 1775/96.
    </p>
    <p>
      Apesar de serem reservadas para uso das populações indígenas, essas terras
      continuam sendo bens da União, sendo reconhecidas a esses povos a posse
      permanente e o usufruto exclusivo dos recursos nelas existentes. Sendo
      assim, é proibido a exploração comercial desses territórios.
    </p>
    <em>Fontes: Greenpeace e Politize!</em>
  </span>
</template>

<script>
export default {
  name: "TextColumnBox2",
};
</script>

<style scoped>
em {
  font-size: 1rem;
}
</style>
