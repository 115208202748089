<template>
  <div>
    <div class="container-fluid p-0">
      <img src="../../assets/images/kevin.png" />
    </div>
    <div class="container px-5 pt-5 pb-4">
      <div class="d-flex justify-content-center flex-column">
        <div class="row justify-content-center">
          <div class="col-8">
            <p>
              <b>Kevin Eduardo</b> é estudante de Jornalismo, redator — e de vez
              em quando fotojornalista e documentarista. Gosta de estar com
              gente.
            </p>
            <p>
              “Finalizar a faculdade com esse projeto me deixa muito orgulhoso.
              Orgulhoso de tudo o que construímos junto aos Deni. O jornalismo é
              fundamental à sociedade, e ele precisa estar ao lado de histórias
              reais, pessoas reais. Reproduzir sempre a versão oficial, de quem
              tem poder, não deve ser a práxis da nossa profissão. A práxis é
              sujar as botas! E foi isso que o Pedro e eu fizemos, assim como
              Dom Phillips e Bruno Araújo, que foram assassinados por contarem
              histórias e defenderem o direito dos povos originários.
              #DomEBrunoPresente.”
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kevin",
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
